.button {
    background-color: #e5e5e5;
    color: #333;
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .button:hover {
    background-color: #d6d6d6;
  }