.coming-soon-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 0 2rem;
    background-color: #f5f5f5;
  }
  
  .title {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .subtitle {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 2rem;
    text-align: center;
    max-width: 30rem;
  }
  
  .countdown-container {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
  }
  
  .countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1rem;
  }
  
  .number {
    font-size: 3rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0.5rem;
  }
  
  .label {
    font-size: 0.8rem;
    color: #666;
    text-transform: uppercase;
  }